import React from 'react';
import './App.scss';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import Provider from './providers';
import useRouter from './hooks/useRouter';
import routes from './routes';

const RouterApp: React.FC = () => {
  const { location } = useRouter();
  return useRoutes(routes, location);
}

const App: React.FC = () => (
  <Provider>
    <BrowserRouter>
      <RouterApp />
    </BrowserRouter>
  </Provider>
);

export default App;
