import { useEffect } from 'react';

import classnames from 'classnames';

import type * as Modal from '../../../types/modal';

const DefaultModal = ({ onAfterOpen, onAfterClose, isOpen = false, onRequestClose, component }: Modal.IModalProps) => {
  useEffect(() => {
    onAfterOpen && onAfterOpen();
    return () => onAfterClose && onAfterClose();
  }, []);

  const handleCloseClick = () => {
    onRequestClose && onRequestClose();
  };

  return (
    <div className={classnames('modal-wrap', isOpen ? 'fade-in' : 'fade-out')}>
      <div className="modal">
        <div className="content">{component && component()}</div>

        <div className="button-wrap">
          <div className="button" onClick={handleCloseClick}>
            Close
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultModal;
